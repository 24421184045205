import React, { useEffect } from 'react';
import { Grid, InputAdornment, makeStyles, MenuItem, Modal, Table } from '@material-ui/core';
import { useState } from 'react';
import { DataGrid, GridCellParams, GridColDef, GridRowParams } from '@material-ui/data-grid';
import AppTable from 'common/AppTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInventory, getSelectedItem } from 'actions/inventroy';
import { addImage, fetchInventoryAPI, getImage, updateItem } from 'services/inventory';
import AppButton from 'common/AppButton';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router';
import * as routes from 'constants/routes';
import SearchBar from 'material-ui-search-bar';
import useStyles from './style';
import AppInput from 'common/AppInput';
import AppSelect from 'common/AppSelect';
import { RootState } from 'store';
import { getProductWithQty, getSalesDetails } from 'services/sales';
import { toast } from 'react-toastify';
import { handleError } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik';
import { CrossIcon } from 'react-select/src/components/indicators';

interface ISalesDetails {
  id?: string;
  type: string;
  date: string;
  itemQuantity: number;
  //unitPrice: number;
  // discountPercent: number;
  discountAmount: number;
  sellingPrice: number;
  totalPrice: number;
  //status: string;
}

const ViewInventory: React.FC = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTxnLoading, setIsTxnLoading] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  //const productList = useSelector((state: RootState) => state.inventory.items);
  const selectedItem = useSelector((state: RootState) => state.inventory.selectedItem);
  const [transactionRows, setTransactioRows] = useState([]);
  const [rowId, setRowId] = useState('');
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [imageSrc, setImageSrc] = useState('');
  const [image, setImage] = useState('');
  const rows = productList.map((product: any) => {
    return {
      id: product.id,
      item: `${product?.brand?.brandName} - ${product?.productName}`,
      quantity: product?.attribute[0]?.inventory[0]?.itemQuantity,
    };
  });

  useEffect(() => {
    async function fetchProducts() {
      let { data } = await fetchInventoryAPI();
      setProductList(data.data);
    }

    fetchProducts();
  }, [dispatch, rowId]);

  useEffect(() => {
    (async () => {
      setIsDetailLoading(true);
      const { data } = await getSalesDetails(rowId);
      const imageData = await getImage(rowId);
      console.log(imageData);

      const productResponse = await getProductWithQty(rowId);
      const salesDetails = data.data;
      const productData = productResponse.data.data;
      const detailRows = salesDetails.map(
        ({
          id,
          name,
          createdAt,
          sellingPrice,
          itemQuantity,
          totalPrice,
          discountAmount,
        }: any): Record<keyof ISalesDetails, any> => ({
          id,
          type: 'Sale',
          date: createdAt,
          sellingPrice: sellingPrice,
          itemQuantity: itemQuantity,
          totalPrice: totalPrice,
          //unitPrice: productData?.item[0].salesPrice,
          // status:
          //   parseInt(productData?.item[0].itemQuantity, 10) <
          //   parseInt(productData?.item[0].itemMinQuantity, 10)
          //     ? 'Not Enough'
          //     : 'Enough',
          // discountPercent: productData?.item[0]?.itemDiscountPercent,
          discountAmount: discountAmount,
        }),
      );
      setTransactioRows(detailRows);
      setSelectedProduct(productData);
      setImageSrc(imageData.data?.data[0]?.url);
    })();
  }, [dispatch, rowId, productList]);

  const [searched, setSearched] = useState<string>('');

  const history = useHistory();

  const columns = [
    { field: 'item', headerName: 'ITEM', flex: 1 },
    { field: 'quantity', headerName: 'Quantity', flex: 1, type: 'number' },
  ];

  const search = (rows: any) => {
    return rows.filter((row: any) => row.item?.toLowerCase().indexOf(query.toLowerCase()) > -1);
  };

  const cancelSearch = () => {
    setQuery('');
    //search(searched);
  };

  const handleAddItem = () => {
    history.push(routes.ADD_INVENTORY_ITEM);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    formik.values.salesPrice = parseInt(selectedProduct?.item[0]?.itemSalesPrice, 10);
    formik.values.itemQuantity = parseInt(selectedProduct?.item[0]?.inventory?.itemQuantity, 10);
    formik.values.discountAmount = parseInt(selectedProduct?.item[0]?.itemDiscountAmount, 10);
    formik.values.discountPercent = parseInt(selectedProduct?.item[0]?.itemDiscountPercent, 10);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleRowClick = (rowData: GridRowParams, event: React.MouseEvent) => {
    setRowId(rowData.id.toString());
    //dispatch(getSelectedItem(rowData.id));
  };

  const [modalOpen, setModalOpen] = useState(false);

  const txncolumns: GridColDef[] = [
    { field: 'type', headerName: 'Type', flex: 1 },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    { field: 'itemQuantity', headerName: 'Quantity', flex: 1 },
    { field: 'sellingPrice', headerName: 'Selling Price', flex: 1 },
    { field: 'discountAmount', headerName: 'Discount Amount', flex: 1 },
    { field: 'totalPrice', headerName: 'Total Price', flex: 1 },
  ];

  const formik = useFormik({
    initialValues: {
      salesPrice: 0,
      itemQuantity: 0,
      discountAmount: 0,
      discountPercent: 0,
      productId: 0,
    },
    onSubmit: () => {},
  });

  const handleUpdate = () => {
    let { values } = formik;
    values.productId = parseInt(selectedProduct?.id, 10);
    (async () => {
      try {
        setIsUpdateLoading(true);
        let imageFormData = new FormData();
        imageFormData.append('file', image);
        await updateItem(values);
        await addImage(values.productId, imageFormData);
        toast.success('Success', {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        let { data } = await fetchInventoryAPI();
        setProductList(data.data);
        formik.resetForm();
        handleClose();
      } catch (error) {
        handleError(error);
      } finally {
        setIsUpdateLoading(false);
      }
    })();
  };

  const selectAttachFile = (e: any) => {
    const filePayload = e.currentTarget.files[0];
    setImage(filePayload);
  };

  const renderUpdataModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Grid item xs={4} className={classes.formContainer}>
          <form className={classes.form}>
            <h2>{selectedItem?.productName}</h2>

            <Grid item xs={12}>
              <AppInput
                label="Quantity"
                fullWidth
                name="itemQuantity"
                type="number"
                value={formik.values.itemQuantity}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <AppInput
                label="Price"
                fullWidth
                name="salesPrice"
                type="number"
                value={formik.values.salesPrice}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AppInput
                label="Discount Amount"
                fullWidth
                name="discountAmount"
                type="number"
                value={formik.values.discountAmount}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AppInput
                label="Discount Percent"
                fullWidth
                name="discountPercent"
                type="number"
                value={formik.values.discountPercent}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AppInput
                type="file"
                fullWidth
                name="item.itemDiscountPercent"
                onChange={selectAttachFile}
              />
            </Grid>

            <div className="buttons-wrapper">
              <AppButton
                variant="contained"
                className={classes.btnModalSubmit}
                type="button"
                onClick={handleUpdate}
                loading={isUpdateLoading}
              >
                Submit
              </AppButton>
            </div>
          </form>
        </Grid>
      </Modal>
    );
  };

  return (
    <>
      {renderUpdataModal()}
      <div className={classes.page}>
        <Grid container spacing={0} xs={4} className={classes.inventoryContainer}>
          <Grid container className={classes.tableHeadercontainer}>
            <Grid item xs={6}>
              <AppButton
                variant="contained"
                className={classes.btnSubmit}
                type="button"
                onClick={handleAddItem}
                loading={isLoading}
              >
                + Add Item
              </AppButton>
            </Grid>
            <Grid item xs={6}>
              <SearchBar
                className={classes.search}
                value={searched}
                onChange={(searchVal) => setQuery(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </Grid>
          </Grid>

          <AppTable rows={search(rows)} columns={columns} onRowClick={handleRowClick} />
        </Grid>
        <div className={classes.col}>
          <Grid container spacing={1} className={classes.detailContainer}>
            {selectedProduct && selectedProduct.item && (
              <>
                <div className={classes.row}>
                  <div className="col">
                    {imageSrc !== undefined && (
                      <Grid item xs={12} className={classes.imageContainer}>
                        <img src={`${imageSrc}`} width="88" height="88" />
                      </Grid>
                    )}
                  </div>
                  <div className={classes.col}>
                    <Grid item xs={12} className={classes.detailHeader}>
                      <h2>{selectedProduct?.productName}</h2>
                      {/* <h2>{`${selectedItem?.brand?.brandName} - ${selectedItem?.productName}`}</h2> */}
                      <AppButton
                        variant="contained"
                        className={classes.btnUpdate}
                        type="button"
                        onClick={handleModalOpen}
                        loading={isLoading}
                        size="small"
                      >
                        Update Item
                      </AppButton>
                    </Grid>

                    <Grid item xs={12} className={classes.detailBody}>
                      <div>
                        <div className={classes.row}>
                          Sale Price:{' '}
                          <p className={classes.posPrice}>
                            {selectedProduct?.item[0]?.itemSalesPrice}
                          </p>{' '}
                        </div>
                        <div className={classes.row}>
                          Purchase Price:{' '}
                          <p className={classes.posPrice}>
                            {selectedProduct?.item[0]?.itemPurchasePrice}
                          </p>{' '}
                        </div>
                      </div>
                      <div>
                        <div className={classes.row}>
                          Stock Quantity:{' '}
                          <p
                            className={
                              parseInt(selectedProduct?.item[0]?.inventory?.itemQuantity, 10) >
                              parseInt(selectedProduct?.item[0]?.inventory?.itemMinQuantity, 10)
                                ? classes.posPrice
                                : classes.negPrice
                            }
                          >
                            {selectedProduct?.item[0]?.inventory?.itemQuantity}
                          </p>{' '}
                        </div>
                        <div className={classes.row}>
                          Stock value:{' '}
                          <p className={classes.posPrice}>
                            {selectedProduct?.item[0]?.inventory?.itemQuantity *
                              selectedProduct?.item[0]?.itemSalesPrice}
                          </p>{' '}
                        </div>
                      </div>
                    </Grid>
                  </div>
                </div>
              </>
            )}
          </Grid>

          <Grid container spacing={1} className={classes.txnContainer}>
            <h2>Transactions</h2>
            {isTxnLoading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <AppTable rows={transactionRows} columns={txncolumns} />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ViewInventory;
