import { PersonAdd } from '@material-ui/icons';
import AppButton from 'common/AppButton';
import AppTable from 'common/AppTable';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCustomers } from 'services/customer';
import { ICustomer } from 'types/ICustomer';
import { handleError } from 'utils';
import { listStyles } from './style';
import * as routes from 'constants/routes';

interface ICustomerRows {
  id: string | number;
  name: string;
  email: string;
  mobile: string;
  phone: string;
}

const CustomerList: React.FC = () => {
  const dispatch = useDispatch();
  const [customerLoading, setCustomerLoading] = useState(false);
  const classes = listStyles();
  const [customerList, setCustomerList] = useState<Array<ICustomer>>([]);
  const history = useHistory();
  useEffect(() => {
    (async () => {
      try {
        setCustomerLoading(true);
        const { data } = await getCustomers();
        const customers = data.data.data.map(
          ({
            id,
            firstName,
            middleName,
            lastName,
            email,
            mobileNumber,
            phoneNumber,
          }: ICustomer): Record<keyof ICustomerRows, any> => ({
            id,
            name: `${firstName} ${middleName} ${lastName}`,
            email: email,
            mobile: mobileNumber,
            phone: phoneNumber,
          }),
        );

        setCustomerList(customers);
      } catch (err) {
        handleError(err);
      } finally {
        setCustomerLoading(false);
      }
    })();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <AppButton
            variant="contained"
            className={classes.btnSubmit}
            type="button"
            onClick={() => history.push(routes.CUSTOMER)}
            style={{ margin: '0px' }}
            // loading={isLoading}
          >
            <PersonAdd /> Add Customer
          </AppButton>
        </div>
        <div className={classes.tableContainer}>
          <AppTable rows={customerList} columns={columns} pageSize={10} />
        </div>
      </div>
    </>
  );
};

export default CustomerList;
