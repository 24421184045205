import { IAction } from 'types';

export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export interface ISidebarAction extends IAction {
  payload: null;
}

export const toggleSidebar = () => ({
  type: SIDEBAR_TOGGLE,
});
