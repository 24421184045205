import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { theme } from 'theme';
import RootRouter from 'components/RootRouter';

const muiTheme = createMuiTheme(theme);

const App: React.FC = () => {
  return (
    <>
      <MuiThemeProvider theme={muiTheme}>
        <RootRouter />
      </MuiThemeProvider>
    </>
  );
};

export default App;
