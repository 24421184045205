import { ISalesAction, SET_TXN_ITEM } from "actions/sales"


type SALES_STATE = {
    salesItems: Array<any>;
}

const INITIAL_STATE = {
    salesItems:[{
        id:0,
        product:'',
        quantity:0,
        unitPrice:0,
        discAmount:0,
        discPercent:0,
        amount:0
    }],
}

export default (state: SALES_STATE = INITIAL_STATE, action:ISalesAction) =>{
    switch (action.type) {
        case SET_TXN_ITEM: {
            let rowIndex = action.payload.rowParams.rowIndex
            let newItem = action.payload.item
            let newTransactionItem = {
                id: newItem?.id,
                product: `${newItem?.brand?.brandName} - ${newItem?.productName}`,
                quantity: 0,
                unitPrice: newItem?.item[0]?.itemSalesPrice,
                discAmount: newItem?.item[0]?.itemDiscountAmount,
                discPercent:  newItem?.item[0]?.itemDiscountPercent,
                amount: 0
            }

            let newTxnItems = state.salesItems;

            newTxnItems[rowIndex] = newTransactionItem

            //let updateTransactionItems = [...state.transactionItems, newItem]
            return {...state, salesItems: newTxnItems }
        }
        default:
            return state;
    }
}