import { IAction } from 'types';

export const SET_TOOLBAR_TITLE = 'SET_TOOLBAR_TITLE';

interface ISetToolbarTitleAction extends IAction {
  payload: string;
}

export const setToolbarTitle = (title: string): ISetToolbarTitleAction => ({
  type: SET_TOOLBAR_TITLE,
  payload: title,
});

export type IToolbarAction = ISetToolbarTitleAction;
