import { IAction } from "types";
export const SET_TXN_ITEM = "SET_TXN_ITEM";

export interface ISalesAction extends IAction {
    payload:  any
}

export const setTxnItem = (item: any, rowParams: any) =>({
    type: SET_TXN_ITEM,
    payload: {item, rowParams}
})