import React from 'react';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core';

type IAppTableProps = {
  checkbox?: boolean;
  onRowClick?: any;
} & DataGridProps;

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '14px',
    height: '90%',
    overflowX: 'hidden',
    '.window': {
      overflowX: 'hidden',
    },
    '.MuiTablePagination-root': {
      overflowX: 'hidden',
    },
  },
}));

const AppTable: React.FC<IAppTableProps> = ({ checkbox = false, ...props }) => {
  const classes = useStyles();
  return (
    <DataGrid
      className={classes.root}
      rows={props.rows}
      columns={props.columns}
      pageSize={props.pageSize}
      checkboxSelection={checkbox}
      onRowClick={props.onRowClick}
    />
  );
};

export default AppTable;
