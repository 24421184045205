import { combineReducers } from 'redux';

import sidebarReducer from './sidebar';
import toolbarReducer from './toolbar';
import inventoryReducer from './inventory';
import salesReducer from './sales';

export default combineReducers({
  sidebar: sidebarReducer,
  toolbar: toolbarReducer,
  inventory: inventoryReducer,
  sales: salesReducer
});
