import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { compose, createStore, applyMiddleware } from 'redux';

import rootReducer from 'reducers';

const enhancers = [applyMiddleware(thunk, promise)];

if (process.env.NODE_ENV === 'development' && (window as any)['__REDUX_DEVTOOLS_EXTENSION__']) {
  enhancers.push((window as any)['__REDUX_DEVTOOLS_EXTENSION__']());
}

export const store = createStore(rootReducer, compose(...enhancers));

export type RootState = ReturnType<typeof rootReducer>;
