import * as urls from "constants/urls";
import { ICustomer } from "types/ICustomer";
import http from "./http";


export const getInvoiceNumber = () => {
    return http.get(urls.GET_INVOICE_NUM);
}
export const postSales = (data:any) => {
    return http.post(urls.POST_SALES, data);
}

export const getSalesDetails = (id:any) => {
    return http.get(`/sales/salesdetails/${id}`)
}

export const getProductWithQty = (id:any) =>{
    return http.get(`/product/${id}`)
}

