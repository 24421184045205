import { FETCH_CATEGORYS, FETCH_INVENTRORY, FETCH_SUBCATEGORYS, GET_SELECTED_ITEM, IInventoryAction } from "actions/inventroy"
import { getProductWithQty, getSalesDetails } from "services/sales"
import { handleError } from "utils"


type INVENTORY_STATE = {
    items: Array<any>;
    categorys: Array<any>;
    subcategorys: Array<any>;
    loading: boolean;
    error: string;
    selectedItem: any;
    transactionItems:Array<any>;
}


const INITIAL_STATE = {
    items: [],
    categorys:[],
    subcategorys:[],
    loading: false,
    error:"",
    selectedItem:null,
    // transactionItems:[{
    //     id: 0,
    //     product: null,
    //     quantity: 0,
    //     unitPrice: 0,
    //     discAmount: 0,
    //     discPercent: 0,
    //     amount: 0,
    //   }],
    transactionItems:[],

}

export default (state: INVENTORY_STATE = INITIAL_STATE, action: IInventoryAction) => {
    switch (action.type) {
        case FETCH_INVENTRORY:
            return {...state, items: action.payload}
        case FETCH_CATEGORYS:
            return {...state, categorys: action.payload}
        case FETCH_SUBCATEGORYS:
            return {...state, subcategorys: action.payload}
        case GET_SELECTED_ITEM: {
            let id = action.payload;
            let items = state.items;
            let requiredItem = items.find((item) => item.id === id)
            return {...state, selectedItem: requiredItem};
        //     let newState =  state;
        //     (async () => {
        //         try{
        //             let { data } = await getProductWithQty(id);
        //             const productData = data.data;
        //              newState =  {...state, selectedItem: productData}
        //         } catch (err) {
        //              newState =  state;
        //         } 
             
        //     })();
        //    return newState;
           
        }
        default:
            return state;
    }
}