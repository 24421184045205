import React from 'react';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

type IAppInputProps = {
  fullwidth?: boolean;
} & TextFieldProps;

// const useStyles = makeStyles((theme) => ({
//   input: {
//     height: '2rem',
//   },
// }));

const AppInput: React.FC<IAppInputProps> = ({ fullwidth, ...props }) => {
  const inputClasses = classNames({
    'w-100': fullwidth,
  });
  //const selectClasses = useStyles();
  return <TextField className={inputClasses} {...props} />;
};

export default AppInput;
