import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IExpensesItem } from 'types';
import * as routes from 'constants/routes';
import { handleError } from 'utils';
import { Grid, InputAdornment, InputLabel } from '@material-ui/core';
import AppButton from 'common/AppButton';
import AppInput from 'common/AppInput';
import { postNewExpensesItem } from 'services/expenses';

const AddExpenses: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const formik = useFormik<IExpensesItem>({
    initialValues: {
      id: 0,
      transactionDate: moment().format('YYYY-MM-DD'),
      description: '',
      quantity: 0,
      unitPrice: 0,
      totalAmount: 0,
    },
    onSubmit: () => {},
  });


  const handleQuantity = (e: any) => {
    const value = Number(e.target.value);
    formik.setFieldValue('quantity', value);
    let { unitPrice } = formik.values;

    formik.setFieldValue('totalAmount', value * unitPrice);
  };

  const handleUnitPrice = (e: any) => {
    const value = Number(e.target.value);
    formik.setFieldValue('unitPrice', value);
    let { quantity } = formik.values;

    formik.setFieldValue('totalAmount', quantity * value);
  };

  const handleSubmit = async () => {
    const formData = formik.values;
    console.log('Request to be sent: ', { formData });

    try {
      setIsLoading(true);
      const { data } = await postNewExpensesItem(formData);
      toast.success('Success', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      formik.resetForm();
      history.push(routes.VIEW_EXPENSES);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form>
      <h1>Expenses</h1>
      <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
          <InputLabel> Expiry date </InputLabel>
          <AppInput
            type="date"
            fullWidth
            name="transactiomDate"
            value={formik.values.transactionDate}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="description"
            fullWidth
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="quantity"
            type="number"
            fullWidth
            name="quantity"
            value={formik.values.quantity}
            onChange={(e) => handleQuantity(e)}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="unit price"
            type="number"
            fullWidth
            name="unitPrice"
            value={formik.values.unitPrice}
            onChange={(e) => handleUnitPrice(e)}
            InputProps={{
              startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="Total Amount"
            type="number"
            fullWidth
            name="totalAmount"
            value={formik.values.totalAmount}
            InputProps={{
              startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <div className="buttons-wrapper">
        <AppButton variant="contained" type="button" onClick={handleSubmit} loading={isLoading}>
          Submit
        </AppButton>
        <AppButton variant="contained" onClick={() => formik.resetForm()}>
          Reset
        </AppButton>
      </div>
    </form>
  );
};
export default AddExpenses;
