import React from 'react';
import { Button, Grid, InputLabel, makeStyles, MenuItem, Modal } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0px 0px 4px',
    color: 'grey',
  },
  row: {
    marginBottom: '24px',
  },
  container: {
    margin: '8px',
    backgroundColor: 'white',
    padding: '30px',
    height: '100%',
    borderRadius: '4px',
  },
  addressContainer: {
    backgroundColor: theme.custom.grey,
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px',
    margin: '8px',
  },
  btnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    marginRight: '16px',
    color: 'white',
  },
  addressHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formContainer: {
    position: 'relative',
  },
  checkboxContainer: {
    position: 'absolute',
    margin: '8px',
  },
  btnDelete: {
    backgroundColor: theme.custom.red,
    margin: '16px',
    color: 'white',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '8px',
  },
}));

export const listStyles = makeStyles((theme) => ({
  container: {
    margin: '8px',
    backgroundColor: 'white',
    padding: '30px',
    minHeight: 'calc(100vh - 50px)',
    borderRadius: '4px',
  },
  formContainer: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    alignContent: 'center',
    padding: '30px',
    maxWidth: '480px',
    borderRadius: '4px',
  },
  form: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
    alignItems: 'flex-start',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  tableContainer: {
    height: 'calc(100vh - 120px)',
  },
  tableFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  btnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    margin: '16px 0px',
    color: 'white',
  },
  custbtnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    margin: '16px 0px',
    color: 'white',
    float: 'right',
  },
  productSearch: {
    width: '100%',
    lineHeight: 'initial',
    fontSize: '14px',
  },
  formLabel: {
    fontWeight: 600,
    marginRight: '24px',
  },
}));
