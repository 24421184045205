import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { AddRounded, FormatLineSpacing } from '@material-ui/icons';
import AppButton from 'common/AppButton';
import AppInput from 'common/AppInput';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postCustomerCreate } from 'services/customer';
import { ICustomerInput } from 'types/ICustomer';
import { handleError } from 'utils';
import * as yup from 'yup';

import { Delete, Remove } from '@material-ui/icons';

import { useStyles } from './style';

const Customer: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let [addressCount, setAddressCount] = useState<number>(1);
  const mobileRegex = /^[0-9]{10}$/g;

  const formik = useFormik<ICustomerInput>({
    initialValues: {
      customer: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        phoneNumber: '',
        password: '',
        rewardsPoints: 0,
      },
      address: [
        {
          provience: '',
          municipality: '',
          district: '',
          ward: '',
          street: '',
          hourseNo: '',
          defaultAddress: false,
        },
      ],
    },
    validationSchema: yup.object().shape({
      customer: yup.object({
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        middleName: yup.string().required('Required'),
        email: yup.string().email('Invalid email').required('Required'),
        mobileNumber: yup
          .string()
          .matches(mobileRegex, 'Please enter a valid mobile number')
          .required('Required'),
      }),
    }),
    onSubmit: () => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    const formData = formik.values;
    console.log('Request to be sent: ', { formData });

    try {
      setIsLoading(true);
      await postCustomerCreate(formData);
      toast.success('Success', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      formik.resetForm();
      //history.push(routes.VIEW_INVENTORY_ITEM);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDefaultAddress = (i: number) => (_: React.ChangeEvent<any>) => {
    const boolValue = _.target.checked;
    let addresses = formik.values.address;
    if (boolValue) {
      addresses.map((address) => {
        if (addresses.indexOf(address) != i) {
          address.defaultAddress = false;
        }
      });
    }
    addresses[i].defaultAddress = boolValue;
    formik.setFieldValue('address', addresses);
    //formik.values.address[i].defaultAddress = boolValue;
    console.log(formik.values.address);
  };

  const renderAddressContainer = () => {
    return Array.from(Array(addressCount), (e, i) => {
      return (
        <Grid className={classes.addressContainer}>
          <Grid className={classes.formContainer} xs={12}>
            <Grid container spacing={4} className={classes.row}>
              <Grid item xs={12} md={3}>
                <AppInput
                  label="State/Province*"
                  fullWidth
                  name={`address[${i}].provience`}
                  value={formik.values?.address[i]?.provience}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  label="District*"
                  fullWidth
                  name={`address[${i}].district`}
                  onChange={formik.handleChange}
                  value={formik.values?.address[i]?.district}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  label="Municipality"
                  fullWidth
                  name={`address[${i}].municipality`}
                  onChange={formik.handleChange}
                  value={formik.values?.address[i]?.municipality}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} className={classes.row}>
              <Grid item xs={12} md={3}>
                <AppInput
                  label="Ward No."
                  fullWidth
                  name={`address[${i}].ward`}
                  onChange={formik.handleChange}
                  value={formik.values?.address[i]?.ward}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  label="Street No.*"
                  fullWidth
                  name={`address[${i}].street`}
                  onChange={formik.handleChange}
                  value={formik.values?.address[i]?.street}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppInput
                  label="House No."
                  fullWidth
                  name={`address[${i}].hourseNo`}
                  onChange={formik.handleChange}
                  value={formik.values?.address[i]?.hourseNo}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.footer}>
              <FormControlLabel
                name="defaultAddress"
                control={
                  <Checkbox
                    checked={formik.values.address[i]?.defaultAddress}
                    onChange={handleDefaultAddress(i)}
                    name={`address[${i}].defaultAddress`}
                    //name="defaultAddress"
                    color="primary"
                    value={formik.values.address[i]?.defaultAddress}
                  />
                }
                label="Mark as default address"
              />

              {i !== 0 && (
                <AppButton
                  variant="contained"
                  className={classes.btnDelete}
                  type="button"
                  onClick={() => handleRemoveAddress(i)}
                  loading={isLoading}
                >
                  <Delete /> Remove
                </AppButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const handleAddAddressClick = () => {
    const newAddress = [...formik.values.address];
    newAddress.push({
      provience: '',
      municipality: '',
      district: '',
      ward: '',
      street: '',
      hourseNo: '',
      defaultAddress: false,
    });
    formik.setFieldValue('address', newAddress);
    setAddressCount(addressCount + 1);
  };

  const handleRemoveAddress = (rowIndex: number): void => {
    const newAddressValue = formik.values.address.filter((_, index) => index !== rowIndex);
    formik.setFieldValue('address', newAddressValue);
    setAddressCount(addressCount - 1);
  };

  return (
    <>
      <form className={classes.container} onSubmit={formik.handleSubmit}>
        <h2 className={classes.title}>Add Customer</h2>
        <Grid container spacing={4} className={classes.row}>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              error={
                formik.errors.customer?.firstName && formik.touched.customer?.firstName
                  ? true
                  : false
              }
            >
              <AppInput
                label="First Name *"
                type="text"
                fullWidth
                name="customer.firstName"
                error={
                  formik.errors.customer?.firstName && formik.touched.customer?.firstName
                    ? true
                    : false
                }
                value={formik.values.customer.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.customer?.firstName && formik.touched.customer?.firstName && (
                <FormHelperText id="component-error-text">
                  {formik.errors.customer?.firstName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              error={
                formik.errors.customer?.middleName && formik.touched.customer?.middleName
                  ? true
                  : false
              }
            >
              <AppInput
                label="Middle Name *"
                type="text"
                name="customer.middleName"
                value={formik.values.customer.middleName}
                onChange={formik.handleChange}
                error={
                  formik.errors.customer?.middleName && formik.touched.customer?.middleName
                    ? true
                    : false
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.customer?.middleName && formik.touched.customer?.middleName && (
                <FormHelperText id="component-error-text">
                  {formik.errors.customer?.middleName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              error={
                formik.errors.customer?.lastName && formik.touched.customer?.lastName ? true : false
              }
            >
              <AppInput
                label="Last Name *"
                type="text"
                fullWidth
                name="customer.lastName"
                value={formik.values.customer.lastName}
                onChange={formik.handleChange}
                error={
                  formik.errors.customer?.lastName && formik.touched.customer?.lastName
                    ? true
                    : false
                }
                onBlur={formik.handleBlur}
              />
              {formik.errors.customer?.lastName && formik.touched.customer?.lastName && (
                <FormHelperText id="component-error-text">
                  {formik.errors.customer?.lastName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.row}>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              error={
                formik.errors.customer?.mobileNumber && formik.touched.customer?.mobileNumber
                  ? true
                  : false
              }
            >
              <AppInput
                label="Mobile *"
                type="text"
                fullWidth
                name="customer.mobileNumber"
                error={
                  formik.errors.customer?.mobileNumber && formik.touched.customer?.mobileNumber
                    ? true
                    : false
                }
                value={formik.values.customer.mobileNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.customer?.mobileNumber && formik.touched.customer?.mobileNumber && (
                <FormHelperText id="component-error-text">
                  {formik.errors.customer?.mobileNumber}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              fullWidth
              error={formik.errors.customer?.email && formik.touched.customer?.email ? true : false}
            >
              <AppInput
                label="Email *"
                type="text"
                fullWidth
                error={
                  formik.errors.customer?.email && formik.touched.customer?.email ? true : false
                }
                name="customer.email"
                value={formik.values.customer.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.customer?.email && formik.touched.customer?.email && (
                <FormHelperText id="component-error-text">
                  {formik.errors.customer?.email}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <AppInput
              label="Password"
              type="password"
              fullWidth
              name="customer.password"
              value={formik.values.customer.password}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <div className={classes.addressHeader}>
          <h2 className={classes.title}>Address </h2>
          <AppButton
            variant="contained"
            className={classes.btnSubmit}
            type="button"
            onClick={() => handleAddAddressClick()}
            loading={isLoading}
          >
            + Add
          </AppButton>
        </div>
        {renderAddressContainer()}
        <div className="buttons-wrapper">
          <AppButton
            variant="contained"
            className={classes.btnSubmit}
            type="submit"
            // onClick={handleSubmit}
            loading={isLoading}
          >
            Submit
          </AppButton>
          <AppButton variant="contained" onClick={() => formik.resetForm()}>
            Reset
          </AppButton>
        </div>
      </form>
    </>
  );
};

export default Customer;
