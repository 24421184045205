import React from 'react';
import { Button, Grid, InputLabel, makeStyles, MenuItem, Modal } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    height: 'calc(100vh - 50px)',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
  },
  inventoryContainer: {
    margin: '8px',
    backgroundColor: 'white',
    padding: '16px',
    width: '100%',
    borderRadius: '4px',
    boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.25)',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '8px',
    backgroundColor: 'white',
    padding: '16px',
    flexWrap: 'nowrap',
    flexGrow: 0,
    width: '100%',
    borderRadius: '4px',
    boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.25)',
  },
  imageContainer: {
    paddingRight: '8px',
  },
  detailHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '8px',
  },
  detailBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  posPrice: {
    color: theme.custom.primaryGreen,
    padding: '0px 3px',
  },
  negPrice: {
    color: theme.custom.red,
    padding: '0px 3px',
  },
  txnContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px',
    backgroundColor: 'white',
    flexWrap: 'nowrap',
    padding: '16px',

    width: '100%',
    flexGrow: 4,
    borderRadius: '4px',
    boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.25)',
  },

  btnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    marginRight: '16px',
    color: 'white',
    height: '40px',
  },
  btnModalSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    margin: '16px 0px',
    color: 'white',
    height: '40px',
    float: 'right',
  },
  btnUpdate: {
    backgroundColor: theme.custom.blue,
    margin: '16px opx',
    color: 'white',
    height: '40px',
    float: 'right',
  },

  search: {
    height: '40px',
  },

  tableHeadercontainer: {
    margin: '8px 4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  formContainer: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    alignContent: 'center',
    padding: '30px',

    borderRadius: '4px',
  },
  form: {
    width: '100%',
  },
}));

export default useStyles;
