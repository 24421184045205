import StoreIcon from '@material-ui/icons/Store';
import { ISidebarItem } from 'types';
import * as routes from 'constants/routes';
import { PersonAdd, Receipt } from '@material-ui/icons';

export type SIDEBAR_ITEM_DIVIDER = 'SIDEBAR_ITEM_DIVIDER';

export const sidebarItems: (ISidebarItem | SIDEBAR_ITEM_DIVIDER)[] = [
  {
    key: 'inventory',
    label: 'Inventory',
    Icon: StoreIcon,
    subItems: [
      {
        key: 'add-inventory-item',
        label: 'Add Items',
        path: routes.ADD_INVENTORY_ITEM,
        toolbarTitle: 'Add Items to Inventory',
      },
      {
        key: 'view-inventory-item',
        label: 'Items',
        path: routes.VIEW_INVENTORY_ITEM,
        toolbarTitle: 'Items',
      },
    ],
  },
  {
    key: 'sale',
    label: 'Sale',
    toolbarTitle: 'Sale',
    Icon: Receipt,
    path: routes.SALE,
  },
  {
    key: 'customer',
    label: 'Customer',
    toolbarTitle: 'Customer',
    Icon: PersonAdd,
    // path: routes.CUSTOMER,
    subItems: [
      {
        key: 'customer',
        label: 'Customer Records',
        toolbarTitle: 'Customer',
        path: routes.VIEW_CUSTOMER,
      },
    ],
  },

  {
    key: 'expenses',
    label: 'Expenses',
    Icon: Receipt,
    subItems: [
      {
        key: 'add-expenses',
        label: 'Add Expenses',
        path: routes.ADD_EXPENSES,
        toolbarTitle: 'Add Expenses',
      },
      {
        key: 'view-expenses',
        label: 'View Expenses',
        path: routes.VIEW_EXPENSES,
        toolbarTitle: 'View Expenses',
      },
    ],
  },
];
