import React from 'react';

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    // TODO: - Log error
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Oops! Something went wrong</h2>
        </div>
      );
    }

    return this.props.children;
  }
}
