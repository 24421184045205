import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputLabel, MenuItem } from '@material-ui/core';

import { handleError } from 'utils';
import AppInput from 'common/AppInput';
import AppTable from 'common/AppTable';
import AppButton from 'common/AppButton';
import AppSelect from 'common/AppSelect';
import * as routes from 'constants/routes';
import { fetchInventoryAPI } from 'services/inventory';

import useStyles from './styles';
import moment from 'moment';
import { Search } from '@material-ui/icons';
import { getCustomerByMobile } from 'services/customer';
import { getInvoiceNumber, postSales } from 'services/sales';

interface ISale {
  id?: number;
  productId: number;
  productName?: string;
  itemQuantity: number;
  salesPrice: number;
  discountAmount: number;
  discountPercent: number;
  invoiceNumber?: number;
  brand?: number;
  inventoryId?: number;
  sellingPrice?: number;
  totalPrice?: number;
  paymentType?: string;
}

interface IFormInitialValues {
  salesDetailInput: ISale[];
  totalTax: number;
  invoiceNumber?: number;
  date: string | null;
  totalDiscountAmount: number;
  totalDiscountPercent: number;
  salesTotal?: number;
  mobile: string;
  customerId?: number;
  customerName?: string;
  extraDiscountAmount?:number;
}

const Sales: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const [invoice, setInvoice] = useState(0);

  const [productOptions, setProductOptions] = useState<any[]>([]);

  const formik = useFormik<IFormInitialValues>({
    initialValues: {
      customerId: 0,
      customerName: '',
      totalDiscountAmount: 0,
      totalDiscountPercent: 0,
      mobile: '',
      totalTax: 0,
      invoiceNumber: 0,
      date: moment().format('YYYY-MM-DD'),
      salesTotal: 0,
      extraDiscountAmount: 0,
      salesDetailInput: [
        {
          id: 0,
          productId: 0,
          itemQuantity: 0,
          salesPrice: 0,
          discountAmount: 0,
          discountPercent: 0,
          sellingPrice: 0,
          totalPrice: 0,
        },
      ],
    },
    onSubmit: () => {},
  });

  const columns = [
    {
      field: 'product',
      headerName: 'Product',
      flex: 1,
      renderCell: function renderCell(params: any) {
        return (
          <Autocomplete
            id="product-search"
            disableClearable
            className={`${classes.productSearch} product-search`}
            value={formik.values.salesDetailInput[params.rowIndex]}
            options={productOptions}
            getOptionLabel={(option: any) => option.productName}
            onChange={handleProductSelect(params.rowIndex)}
            renderInput={(params: any) => <AppInput {...params} placeholder="Search" fullWidth />}
          />
        );
      },
    },
    {
      field: 'itemQuantity',
      headerName: 'Quantity',
      type: 'number',
      width: 120,
      renderCell: function renderCell(params: any) {
        return (
          <AppInput
            type="number"
            name={`salesDetailInput[${params.rowIndex}].itemQuantity`}
            value={formik.values.salesDetailInput[params.rowIndex]?.itemQuantity}
            onChange={handleQuantityChange(params.rowIndex)}
          />
        );
      },
    },
    {
      field: 'salesPrice',
      headerName: 'Unit Price',
      type: 'number',
      width: 120,
      renderCell: function renderCell(params: any) {
        return <span>{formik.values.salesDetailInput[params.rowIndex]?.salesPrice}</span>;
      },
    },
    {
      field: 'discountAmount',
      headerName: 'Discount Amount',
      width: 150,
      renderCell: function renderCell(params: any) {
        return <span>{formik.values.salesDetailInput[params.rowIndex]?.discountAmount}</span>;
      },
    },
    {
      field: 'discountPercent',
      headerName: 'Discount %',
      width: 150,
      renderCell: function renderCell(params: any) {
        return <span>{formik.values.salesDetailInput[params.rowIndex]?.discountPercent}</span>;
      },
    },
    { field: 'totalPrice', headerName: 'Amount', width: 100 },
    {
      field: 'add',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: function renderCell(params: any) {
        return (
          <>
            <AppButton type="button" style={{ color: 'blue' }} onClick={() => handleAddItemClick()}>
              <AddCircleIcon />
            </AppButton>
            {formik.values.salesDetailInput.length > 1 ? (
              <AppButton
                type="button"
                style={{ color: 'red' }}
                onClick={() => handleDeleteItemClick(params.rowIndex)}
              >
                <DeleteIcon />
              </AppButton>
            ) : null}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchInventoryAPI();

        const products = data.data;

        const invoiceData = await getInvoiceNumber();
        const invoice = invoiceData.data.data.id;

        const productOptions = products.map(
          ({ id, brand, productName, attribute }: any): Record<keyof ISale, any> => ({
            id,
            productName: `${brand.brandName} - ${productName}`,
            productId: parseInt(id, 10),
            itemQuantity: 0,
            salesPrice: attribute[0]?.inventory[0].price.itemSalesPrice,
            discountAmount: attribute[0]?.inventory[0].price.itemDiscountAmount,
            discountPercent: attribute[0]?.inventory[0].price.itemDiscountPercent,
            brand: parseInt(brand.id, 10),
            invoiceNumber: 0,
            inventoryId: parseInt(attribute[0]?.inventory[0].id, 10),
            sellingPrice: 0,
            totalPrice: 0,
            paymentType: 'Cash',
          }),
        );

        setProductOptions(productOptions);
        setInvoice(invoice);
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleProductSelect = (index: number) => (_: React.ChangeEvent<any>, newItemVal: any) => {
    const newSalesValue = [...formik.values.salesDetailInput];
    let { id, ...rest } = newItemVal;
    rest.id = index;
    newSalesValue[index] = rest;

    formik.setFieldValue('salesDetailInput', newSalesValue);
    formik.setFieldValue('invoiceNumber', invoice);
  };

  const handleAddItemClick = () => {
    const newSalesValue = [...formik.values.salesDetailInput];
    newSalesValue.push({
      id: newSalesValue.length + 1,
      productId: 0,
      itemQuantity: 0,
      salesPrice: 0,
      discountAmount: 0,
      discountPercent: 0,
    });

    formik.setFieldValue('salesDetailInput', newSalesValue);
  };

  const handleDeleteItemClick = (rowIndex: number): void => {
    const newSalesValue = formik.values.salesDetailInput.filter((_, index) => index !== rowIndex);
    formik.setFieldValue('salesDetailInput', newSalesValue);
  };

  const handleQuantityChange = (index: number) => (event: React.ChangeEvent<any>) => {
    const row = formik.values.salesDetailInput[index];
    const itemQuantity = parseInt(event.target.value, 10);

    // formik.handleChange(event);
    formik.setFieldValue(`salesDetailInput[${index}].itemQuantity`, itemQuantity);

    const { salesPrice, discountAmount } = row;

    if (!itemQuantity || !salesPrice) {
      return;
    }
    const totalPrice = itemQuantity * (salesPrice - discountAmount);
    const sellingPrice = salesPrice - discountAmount;

    formik.setFieldValue(`salesDetailInput[${index}].totalPrice`, totalPrice);
    formik.setFieldValue(`salesDetailInput[${index}].sellingPrice`, sellingPrice);
  };

  const handleModalClose = () => {
    // TODO: - Reset the customer fields
    setModalOpen(false);
  };

  const handleCustomerSubmit = () => {
    // TODO: - From submission
    console.log(formik.values);
  };

  const handleCustomerSearch = () => {
    const mobile = formik.values.mobile;
    (async () => {
      try {
        setIsCustomerLoading(true);
        let response = await getCustomerByMobile(mobile);
        if (response.data.data === null) {
          throw new Error();
        }
        formik.setFieldValue('customerId', parseInt(response.data.data.id, 10));
        formik.setFieldValue(
          'customerName',
          `${response.data.data.firstName} ${response.data.data.middleName} ${response.data.data.lastName}`,
        );
        console.log(response);
      } catch (err) {
        handleError(err, 'Customer Not Found. Please add new customer!');
      } finally {
        setIsCustomerLoading(false);
      }
    })();
  };

  const handleSalesSubmit = () => {
    let { values } = formik;
    const reqInputs = values.salesDetailInput.map(({ productName, id, ...reqItem }) => {
      reqItem.invoiceNumber = formik.values.invoiceNumber;
      return reqItem;
    });
    values = { ...values, salesDetailInput: reqInputs, salesTotal: getTotalValue() };
    const { customerName, mobile, date, ...requestBody } = values;
    const priceBeforeTax = Number(requestBody.salesTotal)/(1+ 0.01*requestBody.totalTax);
    requestBody.totalTax = Number(requestBody.salesTotal)-priceBeforeTax ; 
    //sending tax amount instead of tax percentage
    
    console.log(requestBody);
    (async () => {
      try {
        setIsLoading(true);
        await postSales(requestBody);
        toast.success('Success', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        formik.resetForm();
        history.push(routes.VIEW_INVENTORY_ITEM);
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const getTotalValue = () => {
    const { salesDetailInput, totalTax } = formik.values;

    if ( salesDetailInput.length <= 0) {
      return 0;
    }

    const totalAmount = salesDetailInput.reduce((acc, current) => {
      const amountVal = current?.totalPrice || 0;
      return acc + amountVal;
    }, 0);

    const netAmount = totalAmount * (1 + totalTax / 100);
    const salesTotal = Math.round((netAmount + Number.EPSILON) * 100) / 100;
    //formik.setFieldValue('salesTotal', salesTotal);
    return salesTotal;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.row}>
            <AppInput
              name="mobile"
              type="text"
              placeholder="Enter Mobile number"
              value={formik.values.mobile}
              onChange={formik.handleChange}
            />
            <AppButton
              variant="contained"
              className={classes.btnSubmit}
              type="button"
              style={{ margin: '8px' }}
              loading={isCustomerLoading}
            >
              <Search onClick={() => handleCustomerSearch()} />
            </AppButton>
            <AppButton
              variant="contained"
              className={classes.btnSubmit}
              type="button"
              onClick={() => history.push(routes.CUSTOMER)}
              style={{ margin: '0px' }}
              // loading={isLoading}
            >
              <PersonAdd />
            </AppButton>
          </div>
          <div className={classes.row}>
            <InputLabel id="customer-name" className={classes.formLabel}>
              Customer
            </InputLabel>
            <AppInput name="customername" value={formik.values.customerName} />
          </div>

          <div className="row">
            <div className={classes.row}>
              <InputLabel id="invoice-number" className={classes.formLabel}>
                Invoice Number
              </InputLabel>
              <AppInput
                name="invoiceNumber"
                value={formik.values.invoiceNumber}
                onChange={formik.handleChange}
              />
            </div>
            <div className={classes.row}>
              <InputLabel id="date" className={classes.formLabel}>
                Date
              </InputLabel>
              <AppInput
                type="date"
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className={classes.tableContainer}>
          <AppTable rows={formik.values.salesDetailInput} columns={columns} />
        </div>

        <div className={classes.footer}>
          <AppButton
            variant="contained"
            className={classes.btnSubmit}
            type="button"
            onClick={handleSalesSubmit}
            // loading={isLoading}
          >
            Save Transaction
          </AppButton>
          <div className="row">
            <div className={classes.row}>
              <InputLabel className={classes.formLabel}>Tax: </InputLabel>
              <AppSelect
                labelId="tax-percentage"
                fullWidth
                name="totalTax"
                value={formik.values.totalTax}
                onChange={formik.handleChange}
              >
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={15}>15%</MenuItem>
                <MenuItem value={0}>0%</MenuItem>
              </AppSelect>
            </div>
            <div className={classes.row}>
              <InputLabel className={classes.formLabel}>Total:</InputLabel>
              <span>{getTotalValue()}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sales;
