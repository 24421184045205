import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as routes from 'constants/routes';
import Home from './Home';

const RootRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path={routes.HOME} component={Home} />
      </Switch>
    </Router>
  );
};

export default RootRouter;
