import { ISidebarAction, SIDEBAR_TOGGLE } from 'actions/sidebar';

type SIDEBAR_STATE = {
  isVisible: boolean;
};

const INITIAL_STATE = {
  isVisible: false,
};

export default (state: SIDEBAR_STATE = INITIAL_STATE, action: ISidebarAction) => {
  switch (action.type) {
    case SIDEBAR_TOGGLE:
      return { ...state, isVisible: !state.isVisible };
    default:
      return state;
  }
};
