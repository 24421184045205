import http from './http';

import * as urls from 'constants/urls';
import { IInventoryItem } from 'types';

export const postNewInventoryItem = (data: IInventoryItem) => {
  return http.post(urls.POST_NEW_INVENTORY_ITEM, data);
};

export const fetchInventoryAPI = () => {
  return http.get(urls.GET_INVENTORY_ITEMS)
  
}
export const fetchCategorysAPI =  () => {
  return http.get(urls.GET_PRODUCT_CATEGORYS)
  
}
export const fetchSubCategorysAPI =  () => {
return  http.get(urls.GET_PRODUCT_SUBCATEGORYS)

}

export const updateItem = (data:any) => {
  return http.put(urls.UPDATE_ITEM, data)
}

export const addImage = (productId : number | string, imagePayload: any) => {
  return http.post(`/image/?productId=${productId}&isDefault=true`, imagePayload)
}

export const getImage = (productId: number | string) => {
  return http.get(`/image/find/${productId}`);
}

