import { Button, Grid, InputLabel, makeStyles, MenuItem, Modal } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '8px',
    backgroundColor: 'white',
    padding: '30px',
    minHeight: 'calc(100vh - 50px)',
    borderRadius: '4px',
  },
  formContainer: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    alignContent: 'center',
    padding: '30px',
    maxWidth: '480px',
    borderRadius: '4px',
  },
  form: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '16px',
    alignItems: 'flex-start',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  tableContainer: {
    height: '400px',
  },
  tableFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  btnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    margin: '16px 0px',
    color: 'white',
  },
  custbtnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    margin: '16px 0px',
    color: 'white',
    float: 'right',
  },
  productSearch: {
    width: '100%',
    lineHeight: 'initial',
    fontSize: '14px',
  },
  formLabel: {
    fontWeight: 600,
    marginRight: '24px',
  },
}));

export default useStyles;
