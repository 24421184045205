import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

type IAppButtonProps = {
  loading?: boolean;
} & ButtonProps;

const AppButton: React.FC<IAppButtonProps> = ({
  children,
  loading = false,
  className,
  ...props
}) => {
  return (
    <Button {...props} className={`app-btn ${className}`}>
      {loading ? (
        <>
          <CircularProgress />
          {children}
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default AppButton;
