import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, MenuItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import { handleError } from 'utils';
import { IInventoryItem } from 'types';
import AppInput from 'common/AppInput';
import AppSelect from 'common/AppSelect';
import AppButton from 'common/AppButton';
import {
  addImage,
  fetchCategorysAPI,
  fetchSubCategorysAPI,
  postNewInventoryItem,
} from 'services/inventory';
import * as routes from 'constants/routes';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategorys, fetchSubCategorys } from 'actions/inventroy';
import { ControlPointSharp } from '@material-ui/icons';
import { RootState } from 'store';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0px 0px 4px',
    color: 'grey',
  },
  row: {
    marginBottom: '24px',
  },
  container: {
    margin: '8px',
    backgroundColor: 'white',
    padding: '30px',
    height: '100%',
    borderRadius: '4px',
  },
  btnSubmit: {
    backgroundColor: theme.custom.primaryGreen,
    marginRight: '16px',
    color: 'white',
  },
}));

const Inventory: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const categoryList = useSelector((state: RootState) => state.inventory.categorys);
  const subcategoryList = useSelector((state: RootState) => state.inventory.subcategorys);
  const [image, setImage] = useState('');

  useEffect(() => {
    async function fetchCats() {
      let categorys = await fetchCategorysAPI();

      dispatch(fetchCategorys(categorys.data.data));
      console.log('CATEGORYS', categorys.data.data);
    }
    async function fetchSubCats() {
      let subcategorys = await fetchSubCategorysAPI();

      dispatch(fetchSubCategorys(subcategorys.data.data));
      console.log('SUBCATEGORYS', subcategorys.data.data);
    }

    fetchCats();
    fetchSubCats();
  }, [dispatch]);

  const handleChangeDiscountPrice = (e: any) => {
    const value = parseInt(e.target.value, 10);
    formik.setFieldValue('price.itemDiscountAmount', value);
    let { itemDiscountAmount, itemDiscountPercent, itemSalesPrice } = formik.values.price;

    itemDiscountPercent = (value / itemSalesPrice) * 100;
    let roundedvalue = Math.round((itemDiscountPercent + Number.EPSILON) * 100) / 100;

    formik.setFieldValue('price.itemDiscountPercent', roundedvalue);
  };
  const handleChangeDiscountPercent = (e: any) => {
    const value = parseInt(e.target.value, 10);
    formik.setFieldValue('price.itemDiscountPercent', value);
    let { itemDiscountAmount, itemDiscountPercent, itemSalesPrice } = formik.values.price;

    itemDiscountAmount = (value / 100) * itemSalesPrice;
    let roundedvalue = Math.round((itemDiscountAmount + Number.EPSILON) * 100) / 100;

    formik.setFieldValue('price.itemDiscountAmount', roundedvalue);
  };

  const formik = useFormik<IInventoryItem>({
    initialValues: {
      product: {
        brand: 1,
        productName: '',
        productDescription: '',
        category: 0,
        subCategory: 0,
      },
      attribute: {
        productSize: '',
        productColor: '',
        productMinQuantity: 0,
        productBarcode: '',
      },
      inventory: {
        purchasePrice: 0,
        itemQuantity: 0,
        itemLotNumber: '',
        itemExpiryDate: moment().format('YYYY-MM-DD'),
      },

      price: {
        itemSalesPrice: 0,
        itemDiscountAmount: 0,
        itemDiscountPercent: 0,
        itemOnlinePrice: 1,
      },
    },
    onSubmit: () => {},
  });

  const handleSubmit = async () => {
    const formData = formik.values;
    console.log('Request to be sent: ', { formData });

    try {
      setIsLoading(true);
      const { data } = await postNewInventoryItem(formData);
      let imageFormData = new FormData();
      imageFormData.append('file', image);
      const imageData = await addImage(data.data.id, imageFormData);
      console.log(imageData.data.data);
      toast.success('Success', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      formik.resetForm();
      history.push(routes.VIEW_INVENTORY_ITEM);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const selectAttachFile = (e: any) => {
    const filePayload = e.currentTarget.files[0];
    setImage(filePayload);
  };

  return (
    <form className={classes.container}>
      <h2 className={classes.title}>Brand</h2>
      <Grid container spacing={4} className={classes.row}>
        {/* <Grid item xs={12} md={3}>
          <AppInput
            label="Name"
            fullWidth
            name="brand.brandName"
            value={formik.values.brand.brandName}
            onChange={formik.handleChange}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={3}>
          <AppInput
            label="Description"
            fullWidth
            name="brand.brandDescription"
            value={formik.values.brand.brandDescription}
            onChange={formik.handleChange}
          />
        </Grid> */}
        {/* <h5>{JSON.stringify(formik.values)}</h5> */}
      </Grid>

      <h2 className={classes.title}>Product</h2>

      <Grid container spacing={4} className={classes.row}>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Name"
            fullWidth
            name="product.productName"
            value={formik.values.product.productName}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Description"
            fullWidth
            name="product.productDescription"
            value={formik.values.product.productDescription}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputLabel id="inventory-item-category"> Category </InputLabel>
          <AppSelect
            labelId="inventory-item-category"
            fullWidth
            label="Category"
            name="product.category"
            value={formik.values.product.category}
            onChange={formik.handleChange}
          >
            <MenuItem value="None">
              <em>None</em>
            </MenuItem>
            {categoryList.map((cat: any) => (
              <MenuItem value={cat?.id} key={cat?.id}>
                {cat?.name}
              </MenuItem>
            ))}
          </AppSelect>
        </Grid>
        <Grid item xs={12} md={3}>
          <InputLabel id="inventory-item-description">Sub category</InputLabel>
          <AppSelect
            labelId="inventory-item-description"
            fullWidth
            name="product.subCategory"
            value={formik.values.product.subCategory}
            onChange={formik.handleChange}
          >
            <MenuItem value="None">
              <em>None</em>
            </MenuItem>
            {subcategoryList.map((subcat: any) => (
              <MenuItem value={subcat.id} key={subcat.id}>
                {subcat.name}
              </MenuItem>
            ))}
          </AppSelect>
        </Grid>
      </Grid>
      <h2 className={classes.title}>Item</h2>
      <Grid container spacing={4} className={classes.row}>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Color"
            type="color"
            fullWidth
            name="attribute.productColor"
            value={formik.values.attribute.productColor}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputLabel id="inventory-item-size"> Size </InputLabel>
          <AppSelect
            labelId="inventory-item-size"
            fullWidth
            name="attribute.productSize"
            value={formik.values.attribute.productSize}
            onChange={formik.handleChange}
          >
            <MenuItem value="Large">Large</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Small">Small</MenuItem>
          </AppSelect>
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="Purchase Price"
            type="number"
            fullWidth
            name="inventory.purchasePrice"
            value={formik.values.inventory.purchasePrice}
            onChange={formik.handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Sales Price"
            type="number"
            fullWidth
            name="price.itemSalesPrice"
            value={formik.values.price.itemSalesPrice}
            onChange={formik.handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.row}>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Discount price"
            type="number"
            fullWidth
            name="price.itemDiscountAmount"
            value={formik.values.price.itemDiscountAmount}
            onChange={(e) => handleChangeDiscountPrice(e)}
            InputProps={{
              startAdornment: <InputAdornment position="start">NRs</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="Discount Percent"
            type="number"
            fullWidth
            name="price.itemDiscountPercent"
            value={formik.values.price.itemDiscountPercent}
            onChange={(e) => handleChangeDiscountPercent(e)}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            // /label="Discount Percent"
            type="file"
            fullWidth
            name="price.itemDiscountPercent"
            //value={formik.values.item.itemDiscountPercent}
            onChange={selectAttachFile}
          />
        </Grid>
      </Grid>
      <h2 className={classes.title}>Inventory</h2>
      <Grid container spacing={4} className={classes.row}>
        <Grid item xs={12} md={3}>
          <AppInput
            label="SKU"
            type="text"
            fullWidth
            name="attribute.productBarcode"
            value={formik.values.attribute.productBarcode}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Lot number"
            type="text"
            fullWidth
            name="inventory.itemLotNumber"
            value={formik.values.inventory.itemLotNumber}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputLabel> Expiry date </InputLabel>
          <AppInput
            type="date"
            fullWidth
            name="inventory.itemExpiryDate"
            value={formik.values.inventory.itemExpiryDate}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.row}>
        <Grid item xs={12} md={3}>
          <AppInput
            label="Quantity"
            type="number"
            fullWidth
            name="inventory.itemQuantity"
            value={formik.values.inventory.itemQuantity}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <AppInput
            label="Alert Quantity"
            type="number"
            fullWidth
            name="attribute.productMinQuantity"
            value={formik.values.attribute.productMinQuantity}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <div className="buttons-wrapper">
        <AppButton
          variant="contained"
          className={classes.btnSubmit}
          type="button"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Submit
        </AppButton>
        <AppButton variant="contained" onClick={() => formik.resetForm()}>
          Reset
        </AppButton>
      </div>
    </form>
  );
};

export default Inventory;
