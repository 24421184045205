import React, { useCallback } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SidebarItem from './SidebarItem';
import { sidebarItems } from './sidebar.config';
import { ISidebarItem } from 'types';
import { RootState } from 'store';
import { setToolbarTitle, toggleSidebar } from 'actions';
import { DRAWER_WIDTH } from 'constants/ui';

const INITIAL_DEPTH = 0;
const INITIAL_DEPTH_STEP = 56;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.primary.main,
    padding: '10px 0px',
  },
  sidebar: {
    height: '100vh',
    border: '1px solid rgba(0,0,0,0.1)',
    overflow: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  drawer: {
    boxShadow:
      '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    boxShadow: '6px 0px 4px -3px rgba(0, 0, 0, 0.8)',
    border: 'none',
    borderRadius: 'none',
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isVisible = useSelector((state: RootState) => state.sidebar.isVisible);

  const onSidebarItemClick = useCallback(
    (sidebarItem: ISidebarItem) => {
      const { path, toolbarTitle } = sidebarItem;
      if (!path) {
        return;
      }

      if (toolbarTitle) {
        dispatch(setToolbarTitle(toolbarTitle));
      }
      history.push(path);
    },
    [history, dispatch],
  );

  const renderSidebarItems = useCallback(() => {
    return sidebarItems.map((sidebarItem, index) => (
      <React.Fragment key={index}>
        {typeof sidebarItem === 'string' ? (
          <Divider className="sidebar-item__divider" />
        ) : (
          <SidebarItem
            depthStep={INITIAL_DEPTH_STEP}
            depth={INITIAL_DEPTH}
            sidebarItem={sidebarItem}
            onClick={onSidebarItemClick}
          />
        )}
      </React.Fragment>
    ));
  }, [onSidebarItemClick]);

  const renderSidebar = useCallback(
    () => (
      <div className={`${classes.sidebar} sidebar`}>
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
          {renderSidebarItems()}
        </List>
      </div>
    ),
    [renderSidebarItems, classes.root, classes.sidebar],
  );

  return (
    <nav className={classes.drawer} aria-label="sidebar menus">
      <Hidden smUp implementation="js">
        <Drawer
          variant="temporary"
          open={isVisible}
          onClose={() => dispatch(toggleSidebar())}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {renderSidebar()}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
          {renderSidebar()}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
