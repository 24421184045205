import { IExpensesItem } from "types"
import http from "./http"
import * as urls from 'constants/urls';

export const postNewExpensesItem = (data:IExpensesItem)=>{
    return http.post(urls.POST_NEW_EXPENSES_ITEM, data)
  }

export const getNewExpensesItem = () => {
  return http.get(urls.GET_NEW_EXPENSES_ITEM)
}

export const deleteExpensesItem = (expensesId:number) => {
  return http.delete(`/expenses/${expensesId}`)
}