import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#222F3C',
    },
  },
  custom: {
    fountainBlue: '#61BEBD',
    pewterBlue: '#91a6bb',
    mirage: '#1c2732',
    primaryGreen: '#67D15A',
    grey: '#eceff1',
    blue: '#1e88e5',
    red: '#e53935',
  },
});
