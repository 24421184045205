export const POST_NEW_INVENTORY_ITEM = '/product';
export const GET_INVENTORY_ITEMS = '/product';
export const GET_PRODUCT_CATEGORYS = '/categorys';
export const GET_PRODUCT_SUBCATEGORYS = '/subcategory';
export const POST_NEW_CUSTOMER = '/customer';
export const GET_CUSTOMERS = '/customer';
export const POST_SALES = '/sales/';
export const GET_SALES = '/sales';
export const GET_INVOICE_NUM = '/invoice';
export const UPDATE_ITEM = '/item/updateItem';
export const POST_NEW_EXPENSES_ITEM = '/expenses';
export const GET_NEW_EXPENSES_ITEM = '/expenses';
