import axios from 'axios';

// const DEV_URL = 'http://localhost:4000/api/v1';
const DEV_URL = 'https://blushrush-dev-api.sunai.xyz/api/v1';
const PROD_URL = 'https://blushrush-dev-api.sunai.xyz/api/v1';

const API_URL = process.env.NODE_ENV === 'production' ? PROD_URL : DEV_URL;

const instance = axios.create({
  baseURL: API_URL,
});

export default instance;
