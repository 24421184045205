import { IAction } from "types";

export const FETCH_INVENTRORY = "FETCH_INVENTORY";
export const FETCH_CATEGORYS = "FETCH_CATEORYS";
export const FETCH_SUBCATEGORYS = "FETCH_SUBCATEORYS";
export const GET_SELECTED_ITEM = "GET_SELECTED_ITEM";


export interface IInventoryAction extends IAction {
    payload:  any
}

export const fetchInventory = (items: any) => ({
    type: FETCH_INVENTRORY,
    payload: items
})

export const fetchCategorys = (categorys: any) => ({
    type: FETCH_CATEGORYS,
    payload: categorys
})
export const fetchSubCategorys = (subcategorys: any) => ({
    type: FETCH_SUBCATEGORYS,
    payload: subcategorys
})
export const getSelectedItem = (id: any) => ({
    type: GET_SELECTED_ITEM,
    payload: id
})
