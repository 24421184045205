import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import * as routes from 'constants/routes';
import Inventory from 'components/Inventory';
import ViewInventory from 'components/Inventory/ViewInventory';
import Sale from 'components/Sale';
import Customer from 'components/Customer';
import CustomerList from 'components/Customer/CustomerList';
import AddExpenses from 'components/Expenses';
import ListExpenses from 'components/Expenses/ListExpenses';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.custom.grey,
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: 'calc(100vh - 50px)',
  },
}));

const Home: React.FC = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar />
      <Header />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path={routes.ADD_INVENTORY_ITEM} component={Inventory} />
          <Route path={routes.VIEW_INVENTORY_ITEM} component={ViewInventory} />
          <Route path={routes.SALE} component={Sale} />
          <Route path={routes.VIEW_CUSTOMER} component={CustomerList} />
          <Route path={routes.CUSTOMER} component={Customer} />
          <Route path={routes.ADD_EXPENSES} component={AddExpenses}/>
          <Route path={routes.VIEW_EXPENSES} component={ListExpenses}/>
        </Switch>
      </main>
    </div>
  );
};

export default Home;
