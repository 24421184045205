import { IToolbarAction, SET_TOOLBAR_TITLE } from 'actions';

type TOOLBAR_STATE = {
  title: string;
};

const INITIAL_STATE: TOOLBAR_STATE = {
  title: '',
};

export default (state: TOOLBAR_STATE = INITIAL_STATE, action: IToolbarAction) => {
  switch (action.type) {
    case SET_TOOLBAR_TITLE:
      return { ...state, title: action.payload };
    default:
      return state;
  }
};
