import React from 'react';
import classNames from 'classnames';
import Select, { SelectProps } from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core';

type IAppSelectProps = {
  fullwidth?: boolean;
} & SelectProps;

// const useStyles = makeStyles((theme) => ({
//   select: {
//     height: '2rem',
//   },
// }));

const AppSelect: React.FC<IAppSelectProps> = ({ fullwidth = false, ...props }) => {
  // const selectClasses = useStyles();
  const inputClasses = classNames({
    'w-100': fullwidth,
  });
  return (
    <Select
      className={inputClasses}
      {...props}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    />
  );
};

export default AppSelect;
