import React, { useState } from 'react';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ISidebarItem } from 'types';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    borderLeft: '3px solid transparent',
    padding: '8px',
    color: theme.custom.pewterBlue,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.custom.mirage,
      color: 'white',
      fontWeight: 600,
    },
  },
  menuIcon: {
    color: theme.custom.pewterBlue,
  },
}));

interface ISidebarItemProps {
  depthStep?: number;
  depth?: number;
  sidebarItem: ISidebarItem;
  onClick: (item: ISidebarItem) => void;
}

const SidebarItem: React.FC<ISidebarItemProps> = ({
  depth = 0,
  depthStep = 10,
  sidebarItem,
  onClick,
}) => {
  const { label, Icon, subItems, path } = sidebarItem;
  const classes = useStyles();
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const toggleIsCollapsed = () => setIsCollapsed((prevValue) => !prevValue);

  const sidebarClasses = classNames({
    'sidebar-item': true,
    'sidebar-item--active': path === location.pathname,
  });

  const onMenuClick = () => {
    if (Array.isArray(subItems)) {
      toggleIsCollapsed();
    }

    onClick(sidebarItem);
    // TODO: - Route handler
  };

  const renderExpandIcon = () => {
    let expandIcon = null;

    if (Array.isArray(subItems) && subItems.length) {
      expandIcon = !isCollapsed ? (
        <ExpandLessIcon className="sidebar-item__arrow sidebar-item__arrow--expanded" />
      ) : (
        <ExpandMoreIcon className="sidebar-item__arrow" />
      );
    }

    return expandIcon;
  };

  return (
    <>
      <ListItem className={`${sidebarClasses} ${classes.root}`} onClick={onMenuClick} button>
        <div className="sidebar-item__content" style={{ paddingLeft: depth * depthStep }}>
          {Icon && (
            <ListItemIcon>
              <Icon className={`sidebar-item__icon ${classes.menuIcon}`} />
            </ListItemIcon>
          )}
          <ListItemText primary={label} />
        </div>
        {renderExpandIcon()}
      </ListItem>
      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        {Array.isArray(subItems) ? (
          <List>
            {subItems.map((subItem, index) => (
              <React.Fragment key={index}>
                {typeof subItem === 'string' ? (
                  <Divider className="sidebar-item__divider" />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    sidebarItem={subItem}
                    onClick={onClick}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
};

export default SidebarItem;
