import * as urls from "constants/urls";
import { ICustomerInput } from "types/ICustomer";
import http from "./http";

export const postCustomerCreate = (data: ICustomerInput) => {
    return http.post(urls.POST_NEW_CUSTOMER, data)
}
export const getCustomerByMobile = (data: string) => {
    return http.get(`/customer/${data}`);
}
export const getCustomers = () => {
    return http.get(urls.GET_CUSTOMERS);
}

