import AppButton from 'common/AppButton';
import AppTable from 'common/AppTable';
import { listStyles } from 'components/Customer/style';
import React, { useEffect, useState } from 'react';
import { deleteExpensesItem, getNewExpensesItem } from 'services/expenses';
import { IExpensesItem } from 'types';
import { handleError } from 'utils';
import DeleteIcon from '@material-ui/icons/Delete';

const ListExpenses: React.FC = () => {
  const [refresh, setRefresh] = useState(false);
  const [expensesList, setExpensesList] = useState<Array<IExpensesItem>>([]);
  const classes = listStyles();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getNewExpensesItem();
        const expenses = data.data.map(
          ({
            id,
            transactionDate,
            description,
            quantity,
            unitPrice,
            totalAmount,
          }: IExpensesItem): Record<keyof IExpensesItem, any> => ({
            id,
            transactionDate,
            description,
            quantity,
            unitPrice,
            totalAmount,
          }),
        );

        setExpensesList(expenses);
      } catch (err) {
        handleError(err);
      }
    })();
  }, [refresh]);


  const handleDeleteItemClick = async (rowIndex: number): Promise<void> => {
    try {
      await deleteExpensesItem(expensesList[rowIndex].id);
      setRefresh(!refresh)
    } catch (err) {
      handleError(err);
    }
  };

  const columns = [
    {
      field: 'transactionDate',
      headerName: 'Trnsaction Date',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'unitPrice',
      headerName: 'Unit Price',
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      flex: 1,
    },
    {
      field: 'delete',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: function renderCell(params: any) {
        return (
          <>
            <AppButton
              type="button"
              style={{ color: 'red' }}
              onClick={() => handleDeleteItemClick(params.rowIndex)}
            >
              <DeleteIcon />
            </AppButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className={classes.tableContainer}>
        <AppTable rows={expensesList} columns={columns} pageSize={10} />
      </div>
    </>
  );
};

export default ListExpenses;
