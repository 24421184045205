import { toast } from 'react-toastify';

const GENERIC_ERROR_MESSAGE = 'Oops! Something went wrong';

/**
 *
 * @param event Error Object or any event that triggered the error
 * @param message Optional text to display as error message
 */
export function handleError(event: any, message?: string) {
  const text = message || GENERIC_ERROR_MESSAGE;

  toast.error(text, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}
